import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { initDb, getUserByEmailAndPassword } from '../sqlite';
import { useAuth } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    const db = await initDb();
    const user = getUserByEmailAndPassword(db, email, password);
    if (user) {
      login(user);
      setMessage("Login successful.");
      navigate('/');
    } else {
      setMessage("Invalid email or password.");
    }
  };

  return (
    <Container>
      <Typography variant="h4">Login</Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <TextField label="Email" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Password" type="password" fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
        <Button variant="contained" color="primary" onClick={handleLogin} sx={{ mt: 2 }}>Login</Button>
        {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
      </Box>
    </Container>
  );
};

export default Login;
