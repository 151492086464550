import React from 'react';
import { Container, Typography } from '@mui/material';
//import { Link } from 'react-router-dom';
import POSProductTable from '../components/POSProductTable';

const PointOfSale = () => {
  return (
    <Container>
      <POSProductTable />
    </Container>
  );
};

export default PointOfSale;
