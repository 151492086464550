import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { initDb, getUserByEmail } from '../sqlite';

const RecoverAccount = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleRecover = async () => {
    const db = await initDb();
    const user = getUserByEmail(db, email);
    if (user) {
      setMessage("Password recovery instructions sent to your email.");
      // Enviar un correo con instrucciones para recuperar la contraseña
    } else {
      setMessage("Email not found.");
    }
  };

  return (
    <Container>
      <Typography variant="h4">Recover Account</Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <TextField label="Email" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" color="primary" onClick={handleRecover} sx={{ mt: 2 }}>Recover</Button>
        {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
      </Box>
    </Container>
  );
};

export default RecoverAccount;
