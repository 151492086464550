import React, { useState, useEffect } from 'react';
import { Paper, Typography, Card, CardContent, Container, Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ProductManageDialog from './ProductManageDialog';
import { getDb, getProducts } from '../sqlite';

const ProductsTable = () => {
  const [products, setProducts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const db = getDb();
    setProducts(getProducts(db));
  }, []);

  const handleSaveProduct = (product) => {
    setProducts(prevProducts => {
      const existingProductIndex = prevProducts.findIndex(p => p.id === product.id);
      if (existingProductIndex !== -1) {
        // Actualizar producto existente
        const updatedProducts = [...prevProducts];
        updatedProducts[existingProductIndex] = product;
        return updatedProducts;
      } else {
        // Insertar nuevo producto
        return [...prevProducts, product];
      }
    });
  };

  const handleOpenDialog = (product = null) => {
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginTop: 2 }}>
      <Container sx={{ flexGrow: 1, overflow: 'auto' }}>
      {products.map((product) => (
        <Card component={Paper} key={product.id}           
        sx={{
          marginBottom: 2,
          minHeight: 40, // Ajustar esta propiedad para reducir la altura de cada Card
          '& .MuiCardContent-root': {
            padding: '0px 10px' // Reducir el padding interno de CardContent
          }
        }} 
        onClick={() => handleOpenDialog(product)}>
          <CardContent>
            <Box sx={{ display: 'flex', position: "sticky", alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{product.name}</Typography>
              <Typography color="textSecondary">${product.price.toFixed(2)}</Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
      </Container>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => handleOpenDialog()}
        sx={{ position: 'fixed', bottom: 70, right: 16 }}
      >
        <AddIcon />
      </Fab>
      <ProductManageDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onAddProduct={handleSaveProduct}
        initialProduct={selectedProduct}
      />
    </Box>
  );
};

export default ProductsTable;
