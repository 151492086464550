import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { initDb, registerUser } from '../sqlite';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    const db = await initDb();
    try {
      registerUser(db, { name, email, password, role });
      setMessage("Registration successful. Please login.");
    } catch (e) {
      setMessage("Error during registration: " + e.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4">Registro</Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <TextField label="Nombre" fullWidth margin="normal" value={name} onChange={(e) => setName(e.target.value)} />
        <TextField label="Correo electronico" fullWidth margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Clave" type="password" fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
        <TextField label="Confirmar Clave" type="password" fullWidth margin="normal" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />

        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={(e) => setRole(e.target.value)}>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="vendor">Vendor</MenuItem>
          </Select>
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleRegister} sx={{ mt: 2 }}>Registrar</Button>
        {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
      </Box>
    </Container>
  );
};

export default Register;
