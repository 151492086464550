import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const NavBar = () => {
  const [title, setTitle] = useState('');
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setTitle('Punto de venta');
        break;
      case '/sales':
        setTitle('Ventas');
        break;
      case '/sale':
        setTitle('Detalle de venta');
        break;
      case '/products':
        setTitle('Productos');
        break;
      case '/inventory':
        setTitle('Inventario');
        break;
      case '/register':
        setTitle('Registro');
        break;
      default:
        setTitle('');
    }
  }, [location.pathname]);

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6">
            Mi negocio - {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
