import React, { useState, useEffect } from 'react';
import {  Card, CardContent, Typography, Container, Box } from '@mui/material';
import InventoryManageDialog from './InventoryManageDialog';
import { getDb, getProducts } from '../sqlite';

const InventoryTable = () => {
  const [products, setProducts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const db = getDb();
    setProducts(getProducts(db));
  }, []);

  const handleSave = (product) => {
    setProducts(prevProducts => {
      const existingProductIndex = prevProducts.findIndex(p => p.id === product.id);
      const updatedProducts = [...prevProducts];
      updatedProducts[existingProductIndex] = product;
      return updatedProducts;
    });
  };

  const handleOpenDialog = (product = null) => {
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginTop: 2 }}>
      <Container sx={{ flexGrow: 1, overflow: 'auto' }}>
        {products.map((product) => (
        <Card key={product.id}         
        sx={{
          marginBottom: 2,
          minHeight: 40, // Ajustar esta propiedad para reducir la altura de cada Card
          '& .MuiCardContent-root': {
            padding: '0px 10px' // Reducir el padding interno de CardContent
          }
        }}  
        onClick={() => handleOpenDialog(product)}>
          <CardContent>
            <Box sx={{ display: 'flex', position: "sticky", alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{product.name}</Typography>
              <Typography color="textSecondary">{product.quantity}</Typography>
            </Box>
          </CardContent>
        </Card>
        ))}
      </Container>
      <InventoryManageDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onAddProduct={handleSave}
        initialProduct={selectedProduct}
      />
    </Box>
  );
};

export default InventoryTable;
