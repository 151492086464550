import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Html5Qrcode } from 'html5-qrcode';

const BarcodeScanner = ({ onScan }) => {
  const [scanning, setScanning] = useState(false);
  const [error, setError] = useState(null);
  const scannerRef = useRef(null);

  useEffect(() => {
    const html5QrCode = new Html5Qrcode("reader");
    scannerRef.current = html5QrCode;

    return () => {
      if (scannerRef.current && scannerRef.current.isScanning) {
        scannerRef.current.stop().catch((err) => {
          console.error("Failed to stop the camera: ", err);
        });
      }
    };
  }, []);

  const startScanning = () => {
    setError(null);
    scannerRef.current.start(
      { facingMode: "environment" },
      {
        fps: 10,
        qrbox: 250,
      },
      (decodedText) => {
        onScan(decodedText);
        stopScanning();
      },
      (err) => {
        console.error(err);
        setError("Scanning failed. Please try again.");
      }
    ).then(() => {
      setScanning(true);
    }).catch((err) => {
      console.error("Failed to start scanning: ", err);
      setError("Failed to start scanning. Please try again.");
    });
  };

  const stopScanning = () => {
    if (scannerRef.current && scannerRef.current.isScanning) {
      scannerRef.current.stop().then(() => {
        setScanning(false);
      }).catch((err) => {
        console.error("Failed to stop the camera: ", err);
        setError("Failed to stop scanning. Please try again.");
      });
    } else {
      setScanning(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div id="reader" style={{ width: '300px', height: '300px' }}></div>
      {error && <Typography color="error">{error}</Typography>}
      {!scanning ? (
        <Button variant="contained" onClick={startScanning}>Start Scanning</Button>
      ) : (
        <Button variant="contained" onClick={stopScanning} sx={{ marginTop: 2 }}>Stop Scanning</Button>
      )}
    </Box>
  );
};

export default BarcodeScanner;
