import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Paper, Typography } from '@mui/material';
import { getDb, getSaleDetailsBySaleId, getSaleById } from '../sqlite';
import { format } from 'date-fns'; 

const SaleDetail = () => {
  const { id } = useParams();
  const [sale, setSale] = useState(null);
  const [items, setItems] = useState([]);

  console.log(id);

  useEffect(() => {
    if (id !== undefined) {
      const db = getDb();
      const saleData = getSaleById(db, id);
      const saleDetails = getSaleDetailsBySaleId(db, id);
      console.log(saleDetails);
      setSale(saleData);
      setItems(saleDetails);
    }
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginTop: 2 }}>
      {sale && (
        <>
          <Typography variant="h6">Detalle de la venta</Typography>
          <Typography variant="body1">Fecha: {new Date(sale.date).toLocaleString()}</Typography>

          <Card sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, backgroundColor: 'lightgray' }}>
            <Box sx={{ flex: 1 }}><Typography variant="h6">Nombre</Typography></Box>
            <Box sx={{ flex: 1 }}><Typography variant="h6">Cantidad</Typography></Box>
            <Box sx={{ flex: 1 }}><Typography variant="h6">Precio</Typography></Box>
            <Box sx={{ flex: 1 }}><Typography variant="h6">Subtotal</Typography></Box>
          </Card>
          {items.map(item => (
              <Card sx={{ display: 'flex', justifyContent: 'space-between', padding: 2, marginBottom: 2 }}>
                <Box sx={{ flex: 1 }}><Typography>{item.name}</Typography></Box>
                <Box sx={{ flex: 1 }}><Typography>{item.quantity}</Typography></Box>
                <Box sx={{ flex: 1 }}><Typography>${item.price.toFixed(2)}</Typography></Box>
                <Box sx={{ flex: 1 }}><Typography>${(item.price * item.quantity).toFixed(2)}</Typography></Box>
              </Card>
          ))}
        </>
      )}
    </Box>
  );
};

export default SaleDetail;
