import React from 'react';
import { Container } from '@mui/material';
//import { Link } from 'react-router-dom';
import ProductsTable from '../components/ProductsTable';

const Products = () => {
  return (
    <Container>
      <ProductsTable />
    </Container>
  );
};

export default Products;
