import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import InventoryTable from '../components/InventoryTable';

const Inventory = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <InventoryTable />
    </Box>
  );
};

export default Inventory;
