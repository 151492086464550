import React, { useState, useEffect } from 'react';
import { Paper, Card, CardContent, Typography, Container, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { initDb, getDb } from '../sqlite';
import { format } from 'date-fns'; 

const SalesTable = () => {
  const [salesData, setSalesData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchData = async () => {
      await initDb();
      const db = getDb();
      const stmt = db.prepare("SELECT * FROM sales");
      const sales = [];
      while (stmt.step()) {
        sales.push(stmt.getAsObject());
      }
      setSalesData(sales);
      const total = sales.reduce((sum, sale) => sum + sale.amount, 0);
      setTotalSales(total);
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', marginTop: 2 }}>
      <Button variant="contained" color="primary">Corte: ${totalSales.toFixed(2)}</Button>
      <Container sx={{ flexGrow: 1, overflow: 'auto', marginTop: 2 }}>
      {salesData.map((sale) => (
        <Card component={Paper} key={sale.id}         
        sx={{
          marginBottom: 2,
          minHeight: 40, // Ajustar esta propiedad para reducir la altura de cada Card
          '& .MuiCardContent-root': {
            padding: '0px 10px' // Reducir el padding interno de CardContent
          }
        }}  onClick={() => navigate(`/sale/${sale.id}`)}>
          <CardContent>
            <Box sx={{ display: 'flex', position: "sticky", alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>ID: {sale.id}</Typography>
              <Typography>{formatDate(sale.date)}</Typography>
              <Typography color="textSecondary">${sale.amount.toFixed(2)}</Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
      </Container>
    </Box>
  );
};

export default SalesTable;
