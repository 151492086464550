import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import { AttachMoney } from '@mui/icons-material';
import InventoryIcon from '@mui/icons-material/Inventory';
import ProductIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const BottomNav = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation>
      {user && (user.role === 'admin' || user.role === 'vendor') && (
        <>
          <BottomNavigationAction label="Inicio" icon={<StoreIcon />} onClick={() => handleNavigation('/')} />
          <BottomNavigationAction label="Ventas" icon={<PersonIcon />} onClick={() => handleNavigation('/sales')} />
        </>
      )}
      {user && user.role === 'admin' && (
        <>
          <BottomNavigationAction label="Productos" icon={<ProductIcon />} onClick={() => handleNavigation('/products')} />
          <BottomNavigationAction label="Inventario" icon={<InventoryIcon />} onClick={() => handleNavigation('/inventory')} />
        </>
      )}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
