import React from 'react';
import { Container, Typography, Box, Fab } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SalesTable from '../components/SalesTable';

const Home = () => {
  return (
    <Container>
        <SalesTable />
    </Container>
  );
};

export default Home;
