import initSqlJs from 'sql.js';

let db;

const initDb = async () => {
  if (!db) {
    const SQL = await initSqlJs({ 
      locateFile: file => `/sql-wasm.wasm` 
    });
    db = new SQL.Database();

    // Crear tablas
    db.run(`CREATE TABLE IF NOT EXISTS users (
      id INTEGER PRIMARY KEY,
      name TEXT,
      email TEXT UNIQUE,
      password TEXT,
      role TEXT
    )`);

    db.run(`CREATE TABLE IF NOT EXISTS sales (
      id INTEGER PRIMARY KEY,
      date TEXT,
      amount INTEGER
    )`);

    db.run(`CREATE TABLE IF NOT EXISTS products (
      id INTEGER PRIMARY KEY,
      name TEXT,
      price REAL,
      quantity INTEGER,
      barcode TEXT
    )`);

    db.run(`CREATE TABLE IF NOT EXISTS sale_details (
      id INTEGER PRIMARY KEY,
      sale_id INTEGER,
      product_id INTEGER,
      quantity INTEGER,
      price REAL,
      FOREIGN KEY (sale_id) REFERENCES sales(id),
      FOREIGN KEY (product_id) REFERENCES products(id)
    )`);

    // Insertar roles
    db.run(`INSERT INTO users (name, email, password, role) VALUES 
      ('Admin Business', 'admin@example.com', 'adminpassword', 'admin'),
      ('Vendor Business', 'vendor@example.com', 'vendorpassword', 'vendor')
    `);

    // Cargar datos desde localStorage
    const savedData = localStorage.getItem('sqlite_db');
    if (savedData) {
      const data = JSON.parse(savedData);
      db.run('BEGIN TRANSACTION;');
      data.forEach(({ table, rows }) => {
        rows.forEach(row => {
          const keys = Object.keys(row).join(', ');
          const values = Object.values(row).map(value => `'${value}'`).join(', ');
          db.run(`INSERT INTO ${table} (${keys}) VALUES (${values});`);
        });
      });
      db.run('COMMIT;');
    }

  }
  return db;
};

const saveDb = () => {
  const products = getProducts(db);
  const sales = getSales(db);
  const saleDetails = getSaleDetails(db); 

  const data = [
    { table: 'products', rows: products },
    { table: 'sales', rows: sales },
    { table: 'sale_details', rows: saleDetails }
  ];

  localStorage.setItem('sqlite_db', JSON.stringify(data));
};

const insertProduct = (db, product) => {
  const stmt = db.prepare("INSERT INTO products (name, price, quantity) VALUES (?, ?, ?)");
  stmt.run([product.name, product.price, 0]);
  stmt.free();
  const id = db.exec("SELECT last_insert_rowid() as id")[0].values[0][0];
  saveDb();
  return id;
};

const getProducts = (db) => {
  const stmt = db.prepare("SELECT * FROM products");
  const products = [];
  while (stmt.step()) {
    products.push(stmt.getAsObject());
  }
  stmt.free();
  return products;
};

const getProductById = (db, id) => {
  const stmt = db.prepare("SELECT * FROM products WHERE id = ?");
  stmt.bind([id]);
  const product = stmt.step() ? stmt.getAsObject() : null;
  stmt.free();
  return product;
};

const getProductByBarcode = (db, barcode) => {
  const stmt = db.prepare("SELECT * FROM products WHERE barcode = ?");
  stmt.bind([barcode]);
  const product = stmt.step() ? stmt.getAsObject() : null;
  stmt.free();
  return product;
}

const updateProduct = (db, product) => {
  const stmt = db.prepare("UPDATE products SET name = ?, price = ? WHERE id = ?");
  stmt.run([product.name, product.price, product.id]);
  stmt.free();
  saveDb();
};

const updateProductQuantity = (db, product) => {
  const stmt = db.prepare("UPDATE products SET quantity = ? WHERE id = ?");
  stmt.run([product.quantity, product.id]);
  stmt.free();
  saveDb();
};

const getSales = (db) => {
  const stmt = db.prepare("SELECT * FROM sales");
  const sales = [];
  while (stmt.step()) {
    sales.push(stmt.getAsObject());
  }
  stmt.free();
  return sales;
}

const getSaleById = (db, id) => {
  const stmt = db.prepare("SELECT * FROM sales WHERE id = ?");
  stmt.bind([id]);
  const sale = stmt.step() ? stmt.getAsObject() : null;
  stmt.free();
  return sale;
};

const insertSale = (db, sale) => {
  const stmt = db.prepare("INSERT INTO sales (date, amount) VALUES (?, ?)");
  stmt.run([sale.date, sale.amount]);
  stmt.free();
  const id = db.exec("SELECT last_insert_rowid() as id")[0].values[0][0];
  saveDb();
  return id;
};

const updateSale = (db, sale) => {
  const stmt = db.prepare("UPDATE sales SET amount = ? WHERE id = ?");
  stmt.run([sale.amount, sale.id]);
  stmt.free();
  saveDb();
};

const getSaleDetails = (db) => {
  const stmt = db.prepare("SELECT * FROM sale_details");
  const sales = [];
  while (stmt.step()) {
    sales.push(stmt.getAsObject());
  }
  stmt.free();
  return sales;
}

const getSaleDetailsBySaleId = (db, id) => {
  const stmt = db.prepare(`
    SELECT p.name, sd.quantity, sd.price
    FROM sale_details sd 
    INNER JOIN products p ON p.id = sd.product_id 
    WHERE sd.sale_id = ?`
  );
  stmt.bind([id]);
  const items = [];
  while (stmt.step()) {
    items.push(stmt.getAsObject());
  }
  stmt.free();
  return items;
}

const insertSaleDetail = (db, saledProduct) => {
  const stmt = db.prepare("INSERT INTO sale_details (sale_id, product_id, quantity, price) VALUES (?, ?, ?, ?)");
  stmt.run([saledProduct.sale_id, saledProduct.product_id, saledProduct.quantity, saledProduct.price]);
  stmt.free();
  const id = db.exec("SELECT last_insert_rowid() as id")[0].values[0][0];
  saveDb();
  return id;
};

const updateSaleDetail = (db, saleDetail_id, product) => {
  const stmt = db.prepare("UPDATE sale_details SET quantity = ? WHERE id = ?");
  stmt.run([product.quantity, saleDetail_id]);
  stmt.free();
  saveDb();
};

const deleteSaleDetail = (db, saleDetail_id) => {
  const stmt = db.prepare("DELETE FROM sale_details WHERE id = ?");
  stmt.run([saleDetail_id]);
  stmt.free();
  saveDb();
};

const registerUser = (db, user) => {
  const stmt = db.prepare("INSERT INTO users (name, email, password, role) VALUES (?, ?, ?, ?)");
  stmt.run([user.name, user.email, user.password, user.role]);
  stmt.free();
};

const getUserByEmailAndPassword = (db, email, password) => {
  const stmt = db.prepare("SELECT * FROM users WHERE email = ? AND password = ?");
  stmt.bind([email, password]);
  const user = stmt.step() ? stmt.getAsObject() : null;
  stmt.free();
  return user;
};

const getUserByEmail = (db, email) => {
  const stmt = db.prepare("SELECT * FROM users WHERE email = ?");
  stmt.bind([email]);
  const user = stmt.step() ? stmt.getAsObject() : null;
  stmt.free();
  return user;
};

const getDb = () => db;

export { 
  initDb, 
  getDb, 
  insertProduct, getProducts, getProductById, getProductByBarcode, updateProduct, updateProductQuantity, 
  getSales, insertSale, updateSale, getSaleById,
  getSaleDetails, getSaleDetailsBySaleId, insertSaleDetail, updateSaleDetail, deleteSaleDetail,
  registerUser, getUserByEmailAndPassword, getUserByEmail
};
