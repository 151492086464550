import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Snackbar, Button, Typography, Container, CircularProgress } from '@mui/material';
import Home from './pages/Home';
import PointOfSale from './pages/PointOfSale';
import SaleDetail from './pages/SaleDetail';
import Products from './pages/Products';
import Inventory from './pages/Inventory';
import BottomNav from './components/BottomNav';
import NavBar from './components/NavBar';
/* Páginas públicas */
import Register from './pages/Register';
import Login from './pages/Login';
import RecoverAccount from './pages/RecoverAccount';
import PrivateRoute from './components/PrivateRoute';
import RoleBasedRoute from './components/RoleBasedRoute';
import { AuthProvider } from './components/AuthContext';
import './App.css';
import { initDb } from './sqlite';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

function App() {
  const [loading, setLoading] = useState(true);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    const initializeDatabase = async () => {
      await initDb();
      setLoading(false);
    };
    initializeDatabase();

    const onServiceWorkerUpdate = (registration) => {
      setUpdateAvailable(true);
      const updateServiceWorker = () => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          registration.waiting.addEventListener('statechange', (event) => {
            if (event.target.state === 'activated') {
              window.location.reload();
            }
          });
        }
      };
      updateServiceWorker();
    };

    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerUpdate,
    });
  }, []);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography>Loading database...</Typography>
      </Container>
    );
  }

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <NavBar />
          <Container>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/recover-account" element={<RecoverAccount />} />
              <Route element={<PrivateRoute />}>
                <Route element={<RoleBasedRoute allowedRoles={['admin', 'vendor']} />}>
                  <Route exact path="/" element={<PointOfSale />} />
                  <Route path="/sales" element={<Home />} />
                  <Route path="/sale/:id" element={<SaleDetail />} />
                </Route>
                <Route element={<RoleBasedRoute allowedRoles={['admin']} />}>
                  <Route path="/products" element={<Products />} />
                  <Route path="/inventory" element={<Inventory />} />
                </Route>
              </Route>
            </Routes>
          </Container>
          <BottomNav />
        </div>
      </Router>
      <Snackbar
        open={updateAvailable}
        message="New version available! Reload to update."
        action={
          <Button color="secondary" size="small" onClick={() => window.location.reload()}>
            Reload
          </Button>
        }
      />
    </AuthProvider>
  );
}

export default App;
