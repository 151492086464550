import React, { useState } from 'react';
import { Card, CardContent, Paper, Button, Typography, Fab, Box, Container, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ProductCaptureDialog from './ProductCaptureDialog';
import BarcodeScanner from './BarcodeScanner';
import { getDb, deleteSaleDetail, updateSale, getProductByBarcode, insertSaleDetail } from '../sqlite';
import '../App.css';
import { ForkLeft } from '@mui/icons-material';

const POSProductTable = () => {
  const [products, setProducts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [saleId, setSaleId] = useState(null);
  const [scannedProduct, setScannedProduct] = useState(null);
  const [isScanning, setIsScanning] = useState(false);

  const handleAddProduct = (product) => {
    setProducts([...products, product]);
  };

  const calculateTotal = () => {
    const db = getDb();
    const result = products.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    const sale = {
      id: saleId,
      amount: result
    };
    updateSale(db, sale);
    return result;
  };

  const updateSaleId = (newValue) => {
    setSaleId(newValue);
  };

  const handleDeleteItem = (product_id) => {
    const db = getDb();
    deleteSaleDetail(db, product_id);
    setProducts(products.filter(product => product.id !== product_id));
  };

  const handleScan = (barcode) => {
    const db = getDb();
    const product = getProductByBarcode(db, barcode);
    if (product) {
      setScannedProduct(product);
      product.sale_id = saleId
      insertSaleDetail(product);
      setProducts([...products, product]);
    }
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', position: 'sticky', top: 57, alignContent: "center", justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary">Cancelar</Button>
        <Button variant="contained" color="primary">
          Cobrar ${calculateTotal()}
        </Button>
      </Box>
      <Box sx={{ position: 'sticky', top: 10 }}>
      <Button variant="contained" onClick={() => setIsScanning(true)}>Scan Product</Button>
      {isScanning && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <BarcodeScanner onScan={(barcode) => {
            handleScan(barcode);
            setIsScanning(false);
          }} />
      </Box>
      )}
      </Box>
      {products.map((product, index) => (
        <Card component={Paper} key={product.id} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', position: "sticky", alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6" sx={{ fontSize: '1rem' }}>{product.name}</Typography>
              <Box sx={{ textAlign: 'left' }}>
              <Typography color="textSecondary" sx={{ fontSize: '0.875rem' }}>Precio: ${product.price.toFixed(2)}</Typography>
              <Typography color="textSecondary" sx={{ fontSize: '0.875rem' }}>Cantidad: {product.quantity}</Typography>
              <Typography color="textSecondary" sx={{ fontSize: '0.875rem' }}>Subtotal ${(product.price * product.quantity).toFixed(2)}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setDialogOpen(true)}
        sx={{ position: 'fixed', bottom: 70, right: 16 }}
      >
        <AddIcon />
      </Fab>
      <ProductCaptureDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onAddProduct={handleAddProduct}
        updateSaleId={updateSaleId}
        saleId={saleId}
      />
    </Box>
  );
};

export default POSProductTable;
