import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { getDb, updateProductQuantity } from '../sqlite';

const InventoryManageDialog = ({ open, onClose, onAddProduct, initialProduct }) => {
  const [productName, setProductName] = useState('');
  const [productQuantity, setProductQuantity] = useState('');

  useEffect(() => {
    if (initialProduct != null) {
      setProductName(initialProduct.name);
      setProductQuantity(initialProduct.quantity);
    }
  }, [initialProduct]);

  const handleSave = () => {
    const db = getDb();

    const product = {
      id: initialProduct.id,
      name: productName,
      quantity: parseFloat(productQuantity),
    };

    updateProductQuantity(db, product);
    onAddProduct(product);
    setProductName('');
    setProductQuantity('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Agregar producto</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Cantidad"
          type="number"
          value={productQuantity}
          onChange={(e) => setProductQuantity(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InventoryManageDialog;
