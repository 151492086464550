import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { insertProduct, updateProduct, getDb } from '../sqlite';

const ProductManageDialog = ({ open, onClose, onAddProduct, initialProduct }) => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');

  useEffect(() => {
    if (initialProduct) {
      setProductName(initialProduct.name);
      setProductPrice(initialProduct.price);
    } else {
      setProductName('');
      setProductPrice('');
    }
  }, [initialProduct]);

  const handleSave = () => {
    const db = getDb();

    const product = {
      name: productName,
      price: parseFloat(productPrice),
    };

    if (initialProduct) {
      // Actualizar producto existente
      product.id = initialProduct.id;
      updateProduct(db, product);
    } else {
      // Insertar nuevo producto
      const newProductId = insertProduct(db, product);
      product.id = newProductId;
    }
    onAddProduct(product);
    setProductName('');
    setProductPrice('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Agregar producto</DialogTitle>
      <DialogContent>
        <TextField
          label="Nombre"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Precio"
          type="number"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductManageDialog;
