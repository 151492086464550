import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { getDb, getProducts, insertSale, insertSaleDetail } from '../sqlite';

const ProductCaptureDialog = ({ open, onClose, onAddProduct, updateSaleId, saleId }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productPrice, setProductPrice] = useState('');
  const [productQuantity, setProductQuantity] = useState('');
  const quantityInputRef = useRef(null); 

  useEffect(() => {
    const db = getDb();
    setProducts(getProducts(db));
  }, []);

  const handleAddProduct = () => {
    if (productPrice && productQuantity) {
      if (!saleId) {
        const db = getDb();
        const sale = { 
          date: new Date().toISOString(), 
          amount: 0 
        }
        const newSaleId = insertSale(db, sale);
        console.log(newSaleId);
        updateSaleId(newSaleId);
        saleId = newSaleId;
      }

      const saledProduct = {
        sale_id: saleId,
        product_id: selectedProduct.id,
        name: selectedProduct.name,
        price: parseFloat(productPrice),
        quantity: parseInt(productQuantity),
      };

      const db = getDb();
      const newSaleDetailId = insertSaleDetail(db, saledProduct);
      saledProduct.id = newSaleDetailId;
      onAddProduct(saledProduct);
      setProductPrice('');
      setProductQuantity('');
      onClose();
    }
  };

  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
    if (newValue) {
      setProductPrice(newValue.price);
    } else {
      setProductPrice('');
    }

    if (quantityInputRef.current) {
      // Cambiar el foco al campo de precio
      quantityInputRef.current.focus(); 
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Agregar producto</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={products}
          getOptionLabel={(option) => option.name}
          value={selectedProduct}
          onChange={handleProductChange}
          renderInput={(params) => <TextField {...params} label="Selecccione un Producto" variant="outlined" />}
        />
        <TextField
          label="Precio"
          type="number"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Cantidad"
          type="number"
          value={productQuantity}
          onChange={(e) => setProductQuantity(e.target.value)}
          fullWidth
          margin="normal"
          inputRef={quantityInputRef}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleAddProduct} variant="contained" color="primary">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductCaptureDialog;
